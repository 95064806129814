import { BackgroundLines } from '@components';
import { Stack } from '@mui/material';
import {
  HomeAboutUsSection,
  HomeCollaboratorsSection,
  HomeEnvironmentSection,
  HomeHeroDiscoveryClassSection,
  HomeImpressionSection,
  HomeInterviewsSection,
  HomeJourneySection,
  HomeProgramSection,
  HomeSkillsSection,
  MobileFixedButton,
} from '@subsets/home';
import { HomeContactUsSection } from '@subsets/home/contact-us';

const Content = () => {
  return (
    <Stack gap={{ sm: 18, md: 32 }} alignItems="center">
      <Stack>
        <HomeHeroDiscoveryClassSection />
      </Stack>

      <Stack gap={{ sm: 18, md: 32 }} width={'100%'}>
        <HomeProgramSection />
        <HomeSkillsSection />
      </Stack>

      <HomeJourneySection />

      <Stack gap={{ sm: 18, md: 32 }} width={'100%'}>
        <HomeAboutUsSection />
        <HomeImpressionSection />
      </Stack>

      <HomeCollaboratorsSection />
      <HomeEnvironmentSection />

      <Stack gap={{ sm: 18, md: 32 }} width={'100%'} mb={{ sm: 0, md: 32 }}>
        <HomeInterviewsSection />
        <HomeContactUsSection />
      </Stack>

      <MobileFixedButton />
    </Stack>
  );
};

const Page = () => {
  return (
    <BackgroundLines>
      <Content />
    </BackgroundLines>
  );
};

export default Page;
