import React from 'react';
import { LogoProps } from '.';

export const FacebookIcon = ({ width, height, color }: LogoProps) => (
  <svg width={width || '56'} height={height || '56'} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 22V26H22V33H26V26H29L30 22H26V20C26 19.7348 26.1054 19.4804 26.2929 19.2929C26.4804 19.1054 26.7348 19 27 19H30V15H27C25.6739 15 24.4021 15.5268 23.4645 16.4645C22.5268 17.4021 22 18.6739 22 20V22H19Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="24" cy="24" r="23.5" stroke="#D9D9D9" />
  </svg>
);
