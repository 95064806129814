import { PineconeFilledLogo } from '@assets/icons';
import { FacebookIcon } from '@assets/icons/facebook-icon';
import { InstagramIcon } from '@assets/icons/instagram-icon';
import { Box, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';

export const HomeContactUsSection = () => {
  return (
    <Stack direction={{ sm: 'column', md: 'row' }}>
      <Stack flex={1} alignItems="flex-start" gap={2}>
        <PineconeFilledLogo />
        <Typography fontSize={{ lg: '62.4px', md: '36.4px', sm: '31.2px' }} textTransform="uppercase" fontWeight={700} sx={{ transform: 'scaleY(0.76)' }} color="#111111">
          холбоо барих
        </Typography>
        <Typography variant="bodyM" color="#393939">
          <span style={{ fontWeight: 600 }}>Салбар 1:</span> СБД, 1-р хороо, Чингисийн өргөн чөлөө, Гурван гол оффис центр 3 давхар
        </Typography>
        <Link href="https://w3w.co/goofy.leafing.cafe" color="#393939">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Image src="/w3w.svg" width={36} height={36} alt="w3w" />
            <strong>goofy.leafing.cafe</strong>
          </Stack>
        </Link>
        <Typography variant="bodyM" color="#393939">
          <span style={{ fontWeight: 600 }}>Салбар 2:</span> Сансрын Имарт дэлгүүрийн замын хойд талд, Сөүл Бизнес Центр, 3-р давхарт
        </Typography>
        <Link href="https://w3w.co/harp.trees.grudging" color="#393939">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Image src="/w3w.svg" width={36} height={36} alt="w3w" />
            <strong>harp.trees.grudging</strong>
          </Stack>
        </Link>
        <Stack
          direction="row"
          gap={3}
          marginY={{
            sm: 2,
            md: 4,
            lg: 5,
          }}
          alignItems="flex-start"
          width="100%"
        >
          <Link href="https://www.instagram.com/pineconemongolia/" target="_blank" title="Pinecone Instagram" data-cy="Home-Contact-Us-Instagram">
            <Box width={({ spacing }) => ({ sm: spacing(6), md: spacing(5), lg: spacing(7) })} height={({ spacing }) => ({ sm: spacing(6), md: spacing(5), lg: spacing(7) })}>
              <InstagramIcon width="100%" height="100%" />
            </Box>
          </Link>

          <Link href="https://www.facebook.com/pinecone.academy.mongolia" target="_blank" title="Pinecone Facebook" data-cy="Home-Contact-Us-Facebook">
            <Box width={({ spacing }) => ({ sm: spacing(6), md: spacing(5), lg: spacing(7) })} height={({ spacing }) => ({ sm: spacing(6), md: spacing(5), lg: spacing(7) })}>
              <FacebookIcon width="100%" height="100%" />
            </Box>
          </Link>
        </Stack>
      </Stack>

      <Box
        flex={1}
        sx={{
          minHeight: { sm: '250px', md: '371px' },
          background: 'url(/other-images/map.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></Box>
    </Stack>
  );
};
